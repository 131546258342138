import { isMobile } from "react-device-detect";

// As of October 2023, the following works are available.
const WorksData: WorksLinkArray[] = [
  {
    year: 2024,
    data: [
      {
        id: "rough_sketch_2024",
        type: "link",
        title: "Rough Sketch",
        url: "/2024/rough_sketch",
        hasSound: false,
        flashes: false,
      },
      {
        id: "latest_news_today_2024",
        type: "router",
        title: "Latest News Today",
        url: "/2024/latest_news_today",
        hasSound: true,
        flashes: false,
      },
      {
        id: "quadratic_bezier_curves_2024",
        type: "link",
        title: "Quadratic Bezier Curves",
        url: "/2024/quadratic_bezier_curves",
        hasSound: false,
        flashes: false,
      },
    ],
  },
  {
    year: 2023,
    data: [
      {
        id: "building_blocks_2",
        type: "link",
        title: "Building Blocks 2",
        url: "/2023/building-blocks_2",
        target: "_blank",
        hasSound: true,
        flashes: false,
      },
      {
        id: "chord_explorer_2023",
        type: "link",
        title: "Chord Explorer",
        url: "https://ts5h.github.io/chord_explorer",
        target: "_blank",
        hasSound: true,
        flashes: false,
      },
      {
        id: "biased_diamond_ring_2_2023",
        type: "link",
        title: "Biased Diamond Ring 2",
        url: "/2023/biased_diamond_ring_2",
        hasSound: !isMobile,
        flashes: false,
      },
      {
        id: "composition_2023",
        type: "link",
        title: "Composition",
        url: "/2023/composition",
        hasSound: true,
        flashes: false,
      },
    ],
  },
  {
    year: 2021,
    data: [
      {
        id: "waving_circles_2021",
        type: "link",
        title: "Waving Circles",
        url: "/2021/waving_circles",
        hasSound: false,
        flashes: false,
      },
      {
        id: "sphere_2021",
        type: "link",
        title: "Sphere",
        url: "/2021/sphere",
        hasSound: true,
        flashes: false,
      },
      {
        id: "clock_2021",
        type: "link",
        title: "Clock",
        url: "/2021/clock",
        hasSound: true,
        flashes: false,
      },
    ],
  },
  {
    year: 2020,
    data: [
      {
        id: "sky_2020",
        type: "link",
        title: "Sky",
        url: "/2020/sky",
        hasSound: true,
        flashes: false,
      },
      {
        id: "squares_and_lines_2020",
        type: "link",
        title: "Squares & Lines",
        url: "/2020/squares_and_lines",
        hasSound: true,
        flashes: false,
      },
      {
        id: "biased_diamond_ring_2020",
        type: "link",
        title: "Biased Diamond Ring",
        url: "/2020/biased_diamond_ring",
        hasSound: false,
        flashes: false,
      },
    ],
  },
  {
    year: 2019,
    data: [
      {
        id: "generate_collide_2019",
        type: "link",
        title: "Generate / Collide",
        url: "/2019/generate_and_collide",
        hasSound: true,
        flashes: false,
      },
      {
        id: "lost-in-the-rain_2019",
        type: "link",
        title: "Lost in the Rain (Ripples)",
        url: "/2019/lost_in_the_rain",
        hasSound: false,
        flashes: false,
      },
      {
        id: "annoying-boxes_2019",
        type: "link",
        title: "Annoying Boxes",
        url: "/2019/annoying_boxes",
        hasSound: false,
        flashes: false,
      },
      {
        id: "dots_2019",
        type: "link",
        title: "Dots",
        url: "/2019/dots",
        hasSound: false,
        flashes: false,
      },
      {
        id: "fluctuation_2019",
        type: "link",
        title: "Fluctuation",
        url: "/2019/fluctuation",
        hasSound: false,
        flashes: false,
      },
    ],
  },
  {
    year: 2018,
    data: [
      {
        id: "four_directions_2018",
        type: "link",
        title: "4 Directions",
        url: "/2018/four_directions",
        hasSound: false,
        flashes: false,
      },
      {
        id: "numeric_falls_2018",
        type: "link",
        title: "Numeric Falls",
        url: "/2018/numeric_falls",
        hasSound: false,
        flashes: false,
      },
      {
        id: "folds_2018",
        type: "link",
        title: "Folds",
        url: "/2018/folds",
        hasSound: false,
        flashes: false,
      },
      {
        id: "flashing_screen_2018",
        type: "link",
        title: "Flashing Screen",
        url: "/2018/flashing_screen",
        hasSound: true,
        flashes: true,
      },
      {
        id: "mold_2018",
        type: "link",
        title: "Mold",
        url: "/2018/mold",
        hasSound: false,
        flashes: false,
      },
    ],
  },
  {
    year: 2017,
    data: [
      {
        id: "building-blocks_2017",
        type: "link",
        title: "Building Blocks",
        url: "/2017/building-blocks",
        target: "_blank",
        hasSound: false,
        flashes: false,
      },
    ],
  },
  {
    year: 2014,
    data: [
      {
        id: "prelude_2014",
        type: "router",
        title: "Prelude Reconstruction",
        url: "/2014/prelude",
        hasSound: false,
        flashes: false,
      },
    ],
  },
  {
    year: 2009,
    data: [
      {
        id: "untitled_2009",
        type: "router",
        title: "Untitled",
        url: "/2009/untitled",
        hasSound: false,
        flashes: false,
      },
    ],
  },
];

export default WorksData;
